import React, { useEffect, useState } from "react"
import { debounce } from "lodash"
import Swiper from "react-id-swiper"
import "swiper/scss"
import LayerPopupLayout from "./LayerPopupLayout"
import img_swiper_left from "../images/img_swiper_left.svg"
import img_swiper_right from "../images/img_swiper_right.svg"
import temp_img from "../images/temp_img.svg"
import temp_img2 from "../images/temp_img2.svg"
import UserContainer from "../containers/UserContainer"
import Validator from "validator"
import MyContainer from "../containers/MyContainer"
import SwipeableViews from "react-swipeable-views"
import img_popup_profile_left from "../images/img_popup_profile_left.svg"
import img_popup_profile_right from "../images/img_popup_profile_right.svg"
import { dataCheckFn } from "../utils/util"
import StudyItem from "../components/common/StudyItem"
const MessagePopup = ({ targetUserProfile, postTargetUserProfile, messageDetail, getMessageDetail, open, onClose, data = {}, tab = 0 }) => {
  const [message, setMessage] = useState("")
  const [swiperIndex, setSwiperIndex] = useState(0)
  const closePopup = () => {
    setMessage("")
    setSwiperIndex(0)
    onClose()
  }
  const getData = async () => {
    await getMessageDetail({ messageSeq: data.messageSeq, type: tab == 0 ? "RECEIVE" : "SEND" })
    if (Object.keys(data).length > 0) {
      await postTargetUserProfile({ managerUserSeq: data.sendUserSeq || data.receiveUserSeq, managerUserType: "USER" })
    }
  }
  // swiper left
  const onClickSwiperLeft = () => {
    if (swiperIndex > 0) setSwiperIndex(prev => prev - 1)
    else setSwiperIndex(targetUserProfile.participatingStudies.length - 1)
  }
  // swiper right
  const onClickSwiperRight = () => {
    if (swiperIndex < targetUserProfile.participatingStudies.length - 1) setSwiperIndex(prev => prev + 1)
    else setSwiperIndex(0)
  }
  useEffect(() => {
    if (open) {
      getData()
    }
  }, [open])
  return (
    <LayerPopupLayout open={open} onClose={closePopup} isMCloseBtn transparency zIndex={102}>
      {Object.keys(messageDetail).length > 0 && (
        <div className="popup-message-send-container">
          <div className="popup-message-send-inner ">
            <p className="title">메시지 보기</p>
            <div className="popup-message-send-inner-border">
              {/* swiper */}
              <div className="swiper-view">
                <div className="swiper-card">
                  {messageDetail.imageUrl && <img className="none-swiper-card-img" src={messageDetail.imageUrl} alt={"img"} />}
                  <div className="swiper-card-text-box none-swiper-card-text-box">
                    <p>{messageDetail.title || "우리 같이 공부해요!"}</p>
                  </div>
                </div>
              </div>

              {/* message */}
              <div className="message-view">
                <p>
                  To.{" "}
                  {messageDetail.nickname != null && messageDetail.nickname != "" ? (tab == 0 ? data.nickname : messageDetail.nickname) : "nickname"}
                </p>
                <textarea disabled value={messageDetail.content}></textarea>
                <div className="flex-end end-date">{messageDetail.regDatetime}</div>
              </div>

              {dataCheckFn(targetUserProfile) && targetUserProfile.participatingStudies.length > 0 && (
                <div className="popup-message-study-view">
                  <p className="popup-profile-study-view-title">참여중인 스터디</p>

                  <div className="popup-profile-swiper-view">
                    <button className="btn popup-profile-swiper-left" onClick={onClickSwiperLeft}>
                      <img src={img_popup_profile_left} alt="left img" />
                    </button>
                    <button className="btn popup-profile-swiper-right" onClick={onClickSwiperRight}>
                      <img src={img_popup_profile_right} alt="left img" />
                    </button>

                    {dataCheckFn(targetUserProfile) && (
                      <SwipeableViews index={swiperIndex} onChangeIndex={index => setSwiperIndex(index)}>
                        {targetUserProfile.participatingStudies.map((i, idx) => (
                          <StudyItem key={idx} data={i} data2={targetUserProfile} />
                        ))}
                      </SwipeableViews>
                    )}
                  </div>

                  <div className="popup-profile-ball-view">
                    {dataCheckFn(targetUserProfile) &&
                      targetUserProfile.participatingStudies.length <= 10 &&
                      targetUserProfile.participatingStudies.map((i, idx) => (
                        <button
                          key={idx}
                          className={`btn popup-profile-ball-item ${swiperIndex === idx ? "active" : ""}`}
                          onClick={() => setSwiperIndex(idx)}
                        ></button>
                      ))}
                    {dataCheckFn(targetUserProfile) && targetUserProfile.participatingStudies.length > 10 && (
                      <p>
                        {swiperIndex + 1} / {targetUserProfile.participatingStudies.length}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* button */}
          <div className="button-view">
            <button className="btn btn-primary font-white send-button check-btn" onClick={onClose}>
              확인
            </button>
          </div>
        </div>
      )}
    </LayerPopupLayout>
  )
}

export default UserContainer(MyContainer(MessagePopup))
