/*
  마이렛미업 메세지 페이지
*/
import React, { useCallback, useEffect, useState } from "react"
import { debounce } from "lodash"
import { useDispatch } from "react-redux"
import { setProfilePopup } from "../../store/modules/popup"
import { useMediaQuery } from "react-responsive"
import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import LeftSideBar from "../../components/my/LeftSideBar"
import RightSideBar from "../../components/my/RightSideBar"
import MessagePopup from "../../popups/MessagePopup"
import img_checkbox from "../../images/img_checkbox.svg"
import img_checkbox_active from "../../images/img_checkbox_active.svg"
import img_message_send from "../../images/img_message_send.svg"
import img_border_x from "../../images/img_border_x.svg"
import temp_profile from "../../images/editicon.png"
import PrivateRoute from "../../components/privateRoute"
import UserContainer from "../../containers/UserContainer"
import MyContainer from "../../containers/MyContainer"
import { popupFn, rnMessageListener } from "../../utils/util"
import { navigate } from "gatsby-link"

// 마이렛미업 메세지

const COUNT = 20

const Message = props => {
  const { delMessages, getMessageAdd, messageList, getMessage, messageListTotal } = props
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 576 })
  const [checkList, setCheckList] = useState([])
  const [page, setPage] = useState(0)
  const [tab, setTab] = useState(0)
  const [openMessage, setOpenMessage] = useState({ open: false, data: {}, type: 0 })

  //get data
  const getDate = async () => {
    await getMessage({ type: tab, page: 0, count: COUNT })
    setPage(0)
  }

  useEffect(() => {
    getDate()
  }, [tab])

  // 전체 선택 버튼 핸들러
  const onClickAllCheckBox = () => {
    if (checkList.length === messageList.length && messageList.length !== 0) setCheckList([])
    else setCheckList(messageList.map(i => i.messageSeq))
  }

  // 개별 체크 버튼 핸들러
  const onClickCheckBox = messageSeq => {
    const temp = [...checkList]
    const findIndex = temp.findIndex(i => i === messageSeq)

    if (findIndex === -1) {
      temp.push(messageSeq)
      setCheckList(temp)
    } else {
      temp.splice(findIndex, 1)
      setCheckList(temp)
    }
  }

  //더보기
  const increasePage = debounce(async () => {
    // totalCount 조건
    await getMessageAdd({ page: page + 1, count: COUNT, messageList: messageList, type: tab })
    if (COUNT * (page + 1) < messageListTotal) setPage(prev => prev + 1)
  }, 500)

  // 메세지 삭제 버튼
  const onClickDelete = debounce(async messageSeq => {
    let result = await delMessages({ messageSeqs: [messageSeq] })
    if (result) {
      popupFn("삭제되었습니다.")
      await getDate()
    }
    setCheckList([])
  }, 500)

  // 선택 삭제 버튼
  const onClickDeleteList = debounce(async messageSeq => {
    if (checkList.length <= 0) return false
    let result = await delMessages({ messageSeqs: checkList })
    if (result) {
      popupFn("삭제되었습니다.")
      await getDate()
    }
    setCheckList([])
  }, 500)

  const messageDataFn = useCallback(e => {
    let data = JSON.parse(e.data)
    if (data.type == "BACK") navigate(-1)
  }, [])

  useEffect(() => {
    if (window.ReactNativeWebView) {
      document.addEventListener("message", messageDataFn)
    }
    return () => {
      document.removeEventListener("message", messageDataFn)
    }
  }, [])
  return (
    <PrivateRoute>
      <Layout title="내 메세지">
        <SEO title="마이렛미업" />
        <MessagePopup open={openMessage.open} onClose={() => setOpenMessage({ data: {}, open: false })} data={openMessage.data} tab={tab} />
        <div className="d-flex justify-content-center ">
          <LeftSideBar />
          <div className="container my-container">
            <div className="my-buddy-container">
              <p className="title">내 메세지 관리</p>
              <div className="my-message-top-btn-div">
                <div className="my-message-top-btn-view">
                  <button onClick={() => setTab(0)} className={tab == 0 && "active"}>
                    받은 메세지
                  </button>
                  <button onClick={() => setTab(1)} className={tab == 1 && "active"}>
                    보낸 메세지
                  </button>
                </div>
              </div>
              <div className="my-buddy-wrapper my-message-wrapper">
                <div className="list-header-view">
                  <button className="btn check-button" onClick={onClickAllCheckBox}>
                    <img
                      src={checkList.length === messageList.length && messageList.length !== 0 ? img_checkbox_active : img_checkbox}
                      alt="checkbox img"
                    />
                    <p>전체 선택</p>
                  </button>
                  <button onClick={onClickDeleteList} className={`btn delete-button ${checkList.length > 0 ? "del-active-btn" : ""}`}>
                    삭제
                  </button>
                </div>

                <div className="list-body-view">
                  {messageList
                    .filter((i, idx) => idx < COUNT * (page + 1))
                    .map((i, idx) => (
                      <div
                        key={idx}
                        className={`list-item-view ${checkList.findIndex(item => item === i.messageSeq) === -1 ? "" : "active"}`}
                        onClick={() => isMobile && onClickCheckBox(i.messageSeq)}
                      >
                        <button className="btn check-button" onClick={() => onClickCheckBox(i.messageSeq)}>
                          <img
                            src={checkList.findIndex(item => item === i.messageSeq) === -1 ? img_checkbox : img_checkbox_active}
                            alt="checkbox img"
                          />
                        </button>

                        <div className={`wrapper ${checkList.findIndex(item => item === i.messageSeq) === -1 ? "" : "active"}`}>
                          <button
                            className="btn profile-button"
                            onClick={e => {
                              e.stopPropagation()
                              dispatch(setProfilePopup({ open: true, data: { userSeq: i.sendUserSeq || i.receiveUserSeq } }))
                            }}
                          >
                            <img
                              className="profile-img"
                              src={i.profileImage != null && i.profileImage != "" ? i.profileImage : temp_profile}
                              alt="profile img"
                            />
                          </button>
                          <div className="info-view">
                            <div>
                              <p className="user-name">{i.nickname != null ? i.nickname : "nickname"}</p>
                              <p className="address">{i.region3 != null ? i.region3 : ""}</p>
                            </div>

                            <div onClick={() => setOpenMessage({ open: true, data: i })} className="message-view-div">
                              <p>{i.type == "MESSAGE" && "메세지"}</p>
                              <p>{i.regDatetime}</p>
                            </div>
                          </div>
                        </div>

                        <button
                          className={`btn delete-button ${checkList.findIndex(item => item === i.messageSeq) === -1 ? "" : "active"}`}
                          onClick={() => onClickDelete(i.messageSeq)}
                        >
                          <img src={img_border_x} alt="x img" />
                        </button>
                      </div>
                    ))}

                  <button
                    className={`btn btn-primary font-white more-button ${COUNT * (page + 1) < messageListTotal ? "active" : ""}`}
                    onClick={increasePage}
                  >
                    더보기
                  </button>
                </div>
              </div>
            </div>
          </div>
          <RightSideBar />
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default MyContainer(UserContainer(Message))
